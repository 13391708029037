<template>
    <div class="pt-24 pb-20 bg-gray-50">
        <h3 class="mb-6 text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Příklady využití
        </h3>
        <div>
            <wordcloud
            :data="defaultWords"
            nameKey="name"
            valueKey="value"
            :color="myColors"
            :rotate="{from: 0, to:0}"
            font="Inter"
            :fontSize="[18,38]"
            :wordPadding="10"
            :showTooltip="false">
            </wordcloud>
        </div>
    </div>
</template>
<style>
    .tooltip {
        display: none;
    }
</style>
<script>
    import wordcloud from 'vue-wordcloud'

    export default {
    name: 'Usage',
    components: {
        wordcloud
    },
    data() {
        return {
        myColors: ['#1f77b4', '#629fc9'],
        defaultWords: [
            {'name': 'bioplynové stanice','value': 1/18 },
            {'name': 'čerpání ropy','value': 1/12 },
            {'name': 'chaty a chalupy','value': 1/15 },
            {'name': 'čistírny odpadních vod','value': 1/22 },
            {'name': 'distribuční sítě','value': 1/16 },
            {'name': 'dobíjecí stanice elektromobilů','value': 1/30 },
            {'name': 'elektrické ohradníky','value': 1/20 },
            {'name': 'havarijní hladina','value': 1/17 },
            {'name': 'chladící zařízení','value': 1/17 },
            {'name': 'topná zařízení','value': 1/14 },
            {'name': 'kejdové hospodářství','value': 1/20 },
            {'name': 'měření a regulace','value': 1/17 },
            {'name': 'poruchy izolace','value': 1/15 },
            {'name': 'přečerpávací stanice','value': 1/20 },
            {'name': 'provzdušňování vody v rybnících','value': 1/31 },
            {'name': 'regulační systémy skleníků','value': 1/26 },
            {'name': 'samoobslužné výdejní automaty','value': 1/29 },
            {'name': 'solární systémy','value': 1/15 },
            {'name': 'studny','value': 1/6 },
            {'name': 'výpadky motorů','value': 1/14 },
            {'name': 'vysílací věže','value': 1/13 },
            {'name': 'výtahy','value': 1/6 },
            {'name': 'zabezpečovací systémy','value': 1/21 },
            {'name': 'zavlažovací technologie','value': 1/23 },
        ]
        }
    }
    }    
</script>