import Vue from 'vue'
import Router from 'vue-router'

import Homepage from '@/pages/Homepage.vue'

Vue.use(Router)

const router = new Router ({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Homepage,        
        }
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }    
})

export default router