import Vue from 'vue'
import App from './App.vue'
import '@/assets/css/tailwind.css'
import VueGtag from "vue-gtag"
import router from '@/router/router.js'
import VueScrollactive from 'vue-scrollactive'
import VueScrollTo from 'vue-scrollto'

Vue.use(VueGtag, {
  config: { id: "G-29PY0SKEQF" }
});

// Used for highlighting the menu item in the navbar while scrolling

Vue.use(VueScrollactive, {
  offset: 40,
  duration:600
});

// Used for scrolling animation on <a> anywhere. 
// The options should match with VueScrollactive to keep the same animation style
Vue.use(VueScrollTo, {
  offset: -40,
  duration: 600
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>',
  render: h => h(App)
})
