<template>
    <div>
        <scrollactive>        
            <nav class="bg-gray-100 shadow fixed z-50 w-full">             
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="flex justify-between h-16">
                        <div class="flex">
                            <div class="-ml-2 mr-2 flex items-center md:hidden">
                                <button @click="toggleMenu" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                                    <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path :class="{'hidden': menuOpen, 'inline-flex': !menuOpen }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                                    <path :class="{'hidden': !menuOpen, 'inline-flex': menuOpen }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div class="flex-shrink-0 flex items-center">
                                <a href="#HerBox" v-scroll-to="'#HeroBox'"><img class="block h-6 w-auto" src="@/assets/logo-bonega.svg" alt="" /></a>
                            </div>
                            <div class="hidden md:ml-6 md:flex text-links">
                                <a href="#HeroBox" class="scrollactive-item ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none transition duration-150 ease-in-out">
                                    Úvod
                                </a>
                                <a href="#MeasuredValues" class="scrollactive-item ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none transition duration-150 ease-in-out">
                                    Využití
                                </a>                                
                                <a href="#AppFeatures" class="scrollactive-item ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none transition duration-150 ease-in-out">
                                    Ovládání
                                </a>
                                <a href="#TechnicalParameters" class="scrollactive-item ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none transition duration-150 ease-in-out">
                                    Parametry
                                </a>
                                <a href="#Faq" class="scrollactive-item  ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none transition duration-150 ease-in-out">
                                    Časté dotazy
                                </a>                   
                            </div>
                        </div>
                        <div class="hidden md:block flex items-center pt-3">
                            <div class="flex-shrink-0">
                            <a href="#Pricing" class="scrollactive-item relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-700 shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition ease-in-out duration-150">
                                <span>Ceník</span>
                            </a>
                            <a href="https://app.signalizator.cz" target="_blank" class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-red-700 bg-red-100 hover:text-red-600 shadow-sm hover:bg-red-100 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition ease-in-out duration-150 ml-2">
                                <span>Vstup do aplikace</span>
                            </a>                    
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="{block: menuOpen, hidden: !menuOpen}" class="md:hidden">
                    <div class="pt-2 pb-3">
                        <a href="#HeroBox" v-scroll-to="'#HeroBox'" @click="toggleMenu" class="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out sm:pl-5 sm:pr-6">Úvod</a>
                        <a href="#MeasuredValues" v-scroll-to="'#MeasuredValues'" @click="toggleMenu" class="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out sm:pl-5 sm:pr-6">Využití</a>
                        <a href="#AppFeatures" v-scroll-to="'#AppFeatures'" @click="toggleMenu" class="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out sm:pl-5 sm:pr-6">Ovládání</a>
                        <a href="#TechnicalParameters" v-scroll-to="'#TechnicalParameters'" @click="toggleMenu" class="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out sm:pl-5 sm:pr-6">Parametry</a>
                        <a href="#Faq" v-scroll-to="'#Faq'" @click="toggleMenu" class="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out sm:pl-5 sm:pr-6">Časté dotazy</a>                
                        <div>
                            <a href="#Pricing" v-scroll-to="'#Pricing'" @click="toggleMenu" class="block px-4 py-2 border border-transparent leading-5 font-medium rounded-md text-white bg-red-700 shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition ease-in-out duration-150 mx-4 my-2">
                                Ceník
                            </a>
                        </div>
                        <div>
                            <a href="https://app.signalizator.cz" target="_blank" @click="toggleMenu" class="block px-4 py-2 border border-transparent leading-5 font-medium rounded-md text-red-700 bg-red-100 hover:text-red-600 hover:bg-red-50 shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition ease-in-out duration-150 mx-4 my-3">
                                Vstup do aplikace
                            </a>                    
                        </div>
                    </div>
                </div>                   
            </nav>     
        </scrollactive>   
    </div>
</template>
<style>
    .text-links a.is-active {
        @apply border-b-2 border-red-500 text-gray-900
    }
</style>
<script>

    export default {
        name: 'NavBar',
        data: () => ({
            menuOpen: false
        }),
        methods: {
            toggleMenu() {
                this.menuOpen = !this.menuOpen
            }                  
        }
    }
</script>