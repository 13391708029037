<template>
    <div>
        <div id="HeroBox" class="relative bg-white overflow-hidden">
            <div class="max-w-screen-xl mx-auto ">
                <div
                    class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32"
                >
                    <div
                        class="pt-32 md:pt-40 mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8"
                    >
                        <div class="sm:text-center lg:text-left">
                            <h2
                                class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl"
                            >
                                Hlídání obvodů každé 2 minuty
                            </h2>
                            <p
                                class="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
                            >
                                Bez baterie. Bez složitého nastavování. Bez
                                starostí.
                            </p>
                            <div
                                class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start"
                            >
                                <div class="rounded-md shadow">
                                    <a
                                        href="#Pricing"
                                        v-scroll-to="'#Pricing'"
                                        class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-700 hover:bg-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                                    >
                                        Ceník
                                    </a>
                                </div>
                                <div class="mt-3 sm:mt-0 sm:ml-3">
                                    <a
                                        href="https://app.signalizator.cz"
                                        target="_blank"
                                        class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-red-700 bg-red-100 hover:text-red-600 hover:bg-red-50 focus:outline-none focus:shadow-outline focus:border-red-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                                    >
                                        Vstup do aplikace
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <svg
                        class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
                        fill="currentColor"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="none"
                    >
                        <polygon points="50,0 100,0 50,100 0,100" />
                    </svg>
                </div>
            </div>
            <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <img
                    class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                    src="@/assets/img/signalizator_cover.webp"
                    alt=""
                />
            </div>
        </div>

        <MeasuredValues id="MeasuredValues" />
        <Usage />
        <AppFeatures id="AppFeatures" />
        <TechnicalParameters id="TechnicalParameters" />
        <Faq id="Faq" />
        <Pricing id="Pricing" />
        <div class="p-6 container mx-auto">
            <h3
                class="mt-10 text-2xl text-center leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9 mb-10"
            >
                Mohlo by vás také zajímat ...
            </h3>
            <div id="bonega-rss"></div>
        </div>
    </div>
</template>

<script>
import AppFeatures from "@/components/AppFeatures.vue"
import Faq from "@/components/Faq.vue"
import MeasuredValues from "@/components/MeasuredValues.vue"
import Pricing from "@/components/Pricing.vue"
import TechnicalParameters from "@/components/TechnicalParameters.vue"
import Usage from "@/components/Usage.vue"

export default {
    name: "Homepage",
    components: {
        AppFeatures,
        Faq,
        MeasuredValues,
        Pricing,
        TechnicalParameters,
        Usage,
    },
    props: {
        msg: String,
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
